import React from 'react';
import SuggestionForm from "../SuggestionForm";
import SuggestionList from "../SuggestionsList";
import HintList from "../HintsList";

const App = () => (
  <div className="mx1 my-2">
    <div className="container">
      <h1 className="my-4">Quel est le prénom que m'ont donné mes parents ?</h1>
      <p><i>A priori</i>, vous savez déjà que je suis une fille &#x1F600;</p>
      <HintList />
      <SuggestionForm />
      <SuggestionList />
    </div>
  </div>
);

export default App;