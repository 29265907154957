import React from 'react';
import { firebase } from '../Firebase';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const db = firebase.database();
const fpPromise = FingerprintJS.load();

const SuggestionForm = () => {
  const [username, setUsername] = React.useState("");
  const [suggestion, setSuggestion] = React.useState("");
  const [error, setError] = React.useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    db.ref("hints").once("value", (snapshot) => {
      const hintsObjects = snapshot.val();
      const numberOfHint = hintsObjects.length - 1;

      const suggestionsRef = db.ref("suggestions/" + numberOfHint + "/" + encodeURIComponent(username.trim().toLowerCase()).replace(/\./g, '%2E'));

      suggestionsRef.once("value", (snapshot) => {
        const existingSuggestions = snapshot.val();

        if (!existingSuggestions || Object.values(existingSuggestions).length < 3) {
          fpPromise
            .then(fp => fp.get())
            .then(result => suggestionsRef.push({
              suggestion: suggestion.trim().toLowerCase(),
              sentAt: new Intl.DateTimeFormat('fr-BE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              }).format(Date.now()),
              fingerprint: result.visitorId,
            }));

          setSuggestion('');
          setError('');
        } else {
          setError("Tu as déjà fait assez de propositions, reviens quand il y aura un nouvel indice !")
        }
      })
    });
  }

  const isInvalid = username === '' || suggestion === '' || /\s/g.test(suggestion.trim());

  return (
    <div className="align-middle my-4">
      <h3>Tente ta chance !</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-inline form-group">
          <div>
            <label htmlFor="username" className="my-2">Comment veux-tu que je t'appelle ?</label>
            <input
              type="text"
              id="username"
              placeholder="Ton prénom/surnom"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="form-control mr-1 mb-1"
            />
          </div>
          <div>
            <label htmlFor="suggestion" className="my-2">Ta proposition</label>
            <input
              type="text"
              id="suggestion"
              placeholder="Un prénom à la fois, trois tentatives disponibles par indice &#x1F642;"
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
              className="form-control mr-1 mb-1"
            />
          </div>
          <p>
            Elle va apparaître dans la liste ci-dessous.
          </p>
          <button
            disabled={isInvalid}
            type="submit"
            className="btn btn-primary mb-1 col-auto"
          >
            Valider
          </button>
          {error && (<div className="alert alert-warning" role="alert">{error}</div>)}
        </div>
      </form>
    </div>
  );
};

export default SuggestionForm;