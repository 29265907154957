import React from 'react';
import { firebase } from '../Firebase';

const db = firebase.database();

class SuggestionList extends React.Component<{}> {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      suggestions: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    db.ref("suggestions").on('value', (snapshot) => {
      const suggestionsPerHintMap = snapshot.val();

      this.setState({
        suggestions: suggestionsPerHintMap,
        loading: false,
      })
    });
  }

  componentWillUnmount() {
    db.ref("suggestions").off();
  }

  groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  }

  render() {
    const { suggestions, loading } = this.state;

    const queryString = window.location.search;
    console.log(queryString);

    const ranking = this.groupByArray(
      Object.values(suggestions)
        .flat()
        .map(o => Object.entries(o))
        .flat()
        .map(o => {
          const valueElement = Object.values(o);
          return Object.values(valueElement[1])
            .map(o => {
              return {
                suggestion: o.suggestion,
                sentAt: o.sentAt.replace(" à ", " - ").replace(", ", " - "),
                author: valueElement[0],
              }
            });
        })
        .flat()
        .sort((a, b) => (a.sentAt > b.sentAt) ? 1 : ((a.sentAt < b.sentAt) ? -1 : 0))
      ,
      "suggestion"
      )
      .sort((a, b) => (a.values.length > b.values.length) ? 1 : ((a.values.length < b.values.length) ? -1 : 0))
      .reverse();


    return (
      <div className="align-middle my-4">
        <h3>Historique</h3>
        <p>Voici toutes les propositions déjà reçues. La bonne est peut-être dedans, je ne dis
          rien &#x1F910;</p>
        {loading && <div>Chargement...</div>}
        {suggestions &&
        <div>
          {Object.entries(suggestions).reverse().map((value) => (
            <div key={value[0]}>
              <table className="table table-bordered table-hover table-sm w-auto">
                <thead>
                <tr>
                  <th scope="col" colSpan="2">Après {value[0]} indice{value[0] > 1 && "s"}</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(value[1]).map((value) => (
                  <tr key={value[0]}>
                    <th className="text-center text-capitalize">
                      {decodeURIComponent(value[0].replace('%2E', '.'))}
                    </th>
                    <td className="text-center text-capitalize">
                      {Object.values(value[1]).map(v => v.suggestion).join(', ')}
                    </td>
                  </tr>))}
                </tbody>
              </table>
            </div>
          ))}
          {
            (queryString && queryString === "?roxy") &&
            <div>
              <h3>Classement</h3>
              <table className="table table-bordered table-hover table-sm w-auto">
                <thead>
                <tr>
                  <th scope="col" colSpan="2">Popularité des suggestions</th>
                  <th scope="col">Auteurs par ordre chronologique</th>
                </tr>
                </thead>
                <tbody>
                {ranking.map((value) => (
                  <tr key={value.key}>
                    <th
                      className="text-center text-capitalize">
                      {value.key}
                    </th>
                    <td
                      className="text-center text-capitalize">
                      {value.values.length}
                    </td>
                    <td
                      className="text-center text-capitalize">
                      {value.values.map(o => decodeURIComponent(o.author.replace('%2E', '.'))).join(", ")}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          }
        </div>
        }
      </div>
    );
  }
}

export default SuggestionList;