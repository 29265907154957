import React from 'react';
import { firebase } from '../Firebase';

const db = firebase.database();

class HintList extends React.Component<{}> {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hints: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    db.ref("hints").on('value', (snapshot) => {
      const hints = snapshot.val();
      this.setState({
        hints: hints,
        loading: false,
      })
    });
  }

  componentWillUnmount() {
    db.ref("hints").off();
  }

  render() {
    const { hints, loading } = this.state;

    return (
      <div className="align-middle my-4">
        <h3>Et... voici les indices !</h3>
        <p>Mes parents en ajouteront un nouveau chaque jour jusqu'à ma naissance.</p>
        {loading && <div>Chargement...</div>}
        <ol>
          {Object.entries(hints).map((value) => (
            <li key={value[0]}>
              {value[1]} {value[0] === "3" &&
            <ul>
              <li>
                <a
                  className="link-primary"
                  href="https://statbel.fgov.be/sites/default/files/files/documents/bevolking/5.10%20Namen%20en%20voornamen/5.10.%203%20Voornamen%20meisjes%20en%20jongens/Pr%C3%A9noms_Filles_1995-2017.xls"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prénoms Filles 1995-2019
                </a>
              </li>
              <li>
                <a
                  className="link-primary"
                  href="https://statbel.fgov.be/sites/default/files/files/documents/bevolking/5.10%20Namen%20en%20voornamen/5.10.%203%20Voornamen%20meisjes%20en%20jongens/Pr%C3%A9noms_Garcons_1995-2017.xls"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prénoms Garçons 1995-2019
                </a>
              </li>
            </ul>}
            </li>
          ))}
        </ol>
      </div>
    );
  }

}

export default HintList;